import Page from 'component/new_design/layout/Page';
import { SitesTable } from '../partial/tables/sites/SitesTable';

export default function Dashboard() {
  return (
    <Page>
      <SitesTable enableSearch enablePagination />
    </Page>
  );
}
